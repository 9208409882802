import { useEffect, useState, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import {
  formatErrorForAlert,
  assembleDataForChartRolling,
  formatNumberFull,
} from "../../services/UtilsService";
import FetchService from "../../services/FetchService";
import LayoutFullSideNav from "../../components/Layout/LayoutFullSideNav";
import LayoutSection from "../../components/Layout/LayoutSection";
import SmallTitleGraphic from "../../components/Title/SmallTitleGraphic";
import RiqChartsLine from "../../components/RiqCharts/RiqChartsLine";
import KpiBlock from "../../components/Kpi/KpiBlock";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";
import Alert from "../../components/Alert/Alert";
import Refreshing from "../../components/Refreshing/Refreshing";

const Market = () => {
  const title = "Mercato";
  const menu = ["MARKET DEMAND"];
  const { hotelName } = useContext(AccountContext);
  const [alert, setAlert] = useState();

  const [dataMarket, setDataMarket] = useState([]);
  const [dataMarketAvg, setDataMarketAvg] = useState([]);
  const [lastUpdate, setLastUpdate] = useState([]);
  const [isLoadingMarket, setIsLoadingMarket] = useState(false);

  useEffect(() => {
    getMarketDemand();
  }, []);

  // ==================== Market Demand ====================
  const getMarketDemand = () => {
    let api = `/api/dashboard/mercato/${hotelName}/market-demand`;
    setIsLoadingMarket(true);

    FetchService.get(api)
      .then((res) => {
        setDataMarket(
          assembleDataForChartRolling(res.data.data, "market_demand", hotelName)
        );

        setDataMarketAvg(formatNumberFull(res.data.avg));

        setLastUpdate(
          new Date(res.data.last_update).toLocaleDateString("it-IT", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingMarket(false));
  };

  return (
    <LayoutFullSideNav title={title} menu={menu} lastUpdateForce={lastUpdate}>
      {/* <WorkInProgress
        logo={process.env.PUBLIC_URL + "/imgs/lighthouse.jpg"}
        section={title}
        provider={"Lighthouse (formerly OTA Insight)"}
      /> */}
      <Refreshing conditions={[isLoadingMarket]} />
      <Alert
        styleClass={""}
        alertText={alert && alert.message}
        styleColor={alert && alert.messageType}
        onClick={setAlert}
      />

      <LayoutSection>
        <SmallTitleGraphic content={menu[0]} shapeType={1} />
        <RiqChartsLine
          data={dataMarket}
          dataGuide={{
            x: "dt",
            linesData: [
              {
                dataName: "black",
                friendlyName: "Last 60 days",
              },
            ],
          }}
          isLoading={isLoadingMarket}
          xInterval={6}
        />
        <KpiBlock
          valueLeft={`${dataMarketAvg}%`}
          textLeft="MEDIA MARKET DEMAND"
          kpiRight={false}
        />
      </LayoutSection>
    </LayoutFullSideNav>
  );
};

export default Market;
