import { useState, useEffect, useContext } from "react";
import FetchService from "../../services/FetchService";
import { AccountContext } from "../../services/AccountService";
import { formatErrorForAlert } from "../../services/UtilsService";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Alert from "../Alert/Alert";
import "./Layout.css";

const LayoutFullSideNav = ({
  children,
  title,
  menu = [],
  lastUpdate = true, // global
  lastUpdateForce = false, //from single endpoint
}) => {
  const { hotelName } = useContext(AccountContext);
  const [lastUpdateString, setLastUpdateString] = useState();
  const [alert, setAlert] = useState();

  useEffect(() => {
    if (lastUpdate && !lastUpdateForce) {
      getLastUpdate();
    }
  }, []);

  const getLastUpdate = () => {
    let api = `/api/dashboard/pms/lastavailable/lastavailable`;

    FetchService.post(api, { customer: hotelName })
      .then((res) => {
        const [year, month, day] = res.data.run_date
          ? res.data.run_date.split("-")
          : [0, 0, 0];
        const formattedDate = `${day}/${month}/${year}`;
        setLastUpdateString(formattedDate);
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      });
  };

  return (
    <div className="main-layout">
      <Sidebar />
      <div className="w-100">
        <Navbar title={title} menu={menu} />
        <div className="container">{children}</div>
        <Alert
          styleClass={""}
          alertText={alert && alert.message}
          styleColor={alert && alert.messageType}
          onClick={setAlert}
        />
        {lastUpdate && (
          <div className="text-center font-grey mb-4">
            Last update:{" "}
            {lastUpdateForce
              ? lastUpdateForce
              : lastUpdateString
              ? lastUpdateString
              : "-"}
          </div>
        )}
      </div>
    </div>
  );
};

export default LayoutFullSideNav;
