const _variables = {
  black: "#000000",
  white: "#ffffff",
  yellow: "#f8e905",
  grey: "#cccccc",
};

const _variablesChart = {
  black: "#000000",
  yellow: "#f8e905",
  complementaryBlue: "#5005F7",
  extraBlueDark: "#003f5c",
  extraBlue: "#2f4b7c",
  extraVioletDark: "#665191",
  extraViolet: "#a05195",
  complementaryViolet: "#E900FF",
  extraPinkDark: "#d45087",
  extraPink: "#f95d6a",
  extraorange: "#ff7c43",
  extraYellow: "#ffa600",
};

export { _variablesChart };
export default _variables;
