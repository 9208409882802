// array with all days like 01, 02, ..., 31
const days = Array.from({ length: 31 }, (_, index) => ({
  numb: (index + 1).toString().padStart(2, "0"),
  lettFull: (index + 1).toString().padStart(2, "0"),
  lettShort: (index + 1).toString().padStart(2, "0"),
}));

// array with all months in three notations: 01, Gennaio, GEN
const months = [
  {
    numb: "01",
    lettFull: "Gennaio",
    lettShort: "GEN",
  },
  {
    numb: "02",
    lettFull: "Febbraio",
    lettShort: "FEB",
  },
  {
    numb: "03",
    lettFull: "Marzo",
    lettShort: "MAR",
  },
  {
    numb: "04",
    lettFull: "Aprile",
    lettShort: "APR",
  },
  {
    numb: "05",
    lettFull: "Maggio",
    lettShort: "MAG",
  },
  {
    numb: "06",
    lettFull: "Giugno",
    lettShort: "GIU",
  },
  {
    numb: "07",
    lettFull: "Luglio",
    lettShort: "LUG",
  },
  {
    numb: "08",
    lettFull: "Agosto",
    lettShort: "AGO",
  },
  {
    numb: "09",
    lettFull: "Settembre",
    lettShort: "SET",
  },
  {
    numb: "10",
    lettFull: "Ottobre",
    lettShort: "OTT",
  },
  {
    numb: "11",
    lettFull: "Novembre",
    lettShort: "NOV",
  },
  {
    numb: "12",
    lettFull: "Dicembre",
    lettShort: "DIC",
  },
];

// format error to display it in an alert
const formatErrorForAlert = (err) => {
  /* let errFormatted =
      "Something went wrong! Please contact your system administrator"; */

  // for debugging purposes
  let errFormatted =
    err.code +
    ": " +
    (err.response && err.response.data.detail
      ? Array.isArray(err.response.data.detail)
        ? err.response.data.detail.map((obj) => obj.msg).join(", ")
        : err.response.data.detail
      : err.message);

  return errFormatted;
};

// round number to one decimal place
const formatNumberRound = (number) => {
  return typeof number === "number" ? Math.round(number * 10) / 10 : 0;
};

// round number
const formatNumberRoundNoDecimal = (number) => {
  return typeof number === "number" ? Math.round(number) : 0;
};

// round number to one decimal place and format it for italian locale
const formatNumberFull = (number) => {
  return typeof number === "number"
    ? (Math.round(number * 10) / 10).toLocaleString("it-IT", {
        minimumFractionDigits: 1,
      })
    : 0;
};

// round number and format it for italian locale
const formatNumberFullNoDecimal = (number) => {
  return typeof number === "number"
    ? Math.round(number).toLocaleString("it-IT", {
        minimumFractionDigits: 0,
      })
    : 0;
};

// string to title case
const titleCase = (str) => {
  let words = str ? str.toLowerCase().split(" ") : "";
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  return words.join(" ");
};

// format date selected to display: take year or format the month
const formatDateSelected = (calendarMode, date, forChartLabel = false) => {
  if (date) {
    return calendarMode === "decade"
      ? date.getFullYear()
      : calendarMode === "year"
      ? formatMonthSelected(date, forChartLabel)
      : "";
  }
};

// format month selected to display: 01/24 for sidebar or GEN for chart
const formatMonthSelected = (date, forChartLabel = false) => {
  let monthIndex = date.getMonth();

  // es: MAR 24 or 03/24
  return `${
    forChartLabel
      ? months[monthIndex].lettShort + " "
      : (monthIndex + 1).toString().padStart(2, "0") + "/"
  }${date.getFullYear() % 100}`;
};

// compute previous date
const computePreviousDate = (calendarMode, selectedDate) => {
  // year - 1 or month - 1
  /* return calendarMode === "decade"
    ? new Date(
        selectedDate.getFullYear() - 1,
        selectedDate.getMonth(),
        selectedDate.getDate()
      )
    : calendarMode === "year"
    ? new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() - 1,
        selectedDate.getDate()
      )
    : undefined; */

  // always year - 1
  return new Date(
    selectedDate.getFullYear() - 1,
    selectedDate.getMonth(),
    selectedDate.getDate()
  );
};

// format body for api
const assembleBody = (hotelName, selectedDate, calendarMode) => {
  let body = {
    customer: hotelName,
  };

  if (selectedDate) {
    body.year = selectedDate.getFullYear().toString();

    if (calendarMode === "year") {
      body.month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
    }
  }

  return body;
};

// format data to display in charts: combine two arrays in one
const assembleDataForChart = (
  calendarMode,
  dataCurr,
  dataPrev,
  propertyName,
  hotelName
) => {
  // get data from 12 month or 31 days
  const getValueFromArray = (data, timeperiod) => {
    const entry = data.find((entry) => entry.dt.endsWith(`-${timeperiod}`));
    return entry
      ? propertyName === "revenue"
        ? formatNumberRoundNoDecimal(entry[propertyName])
        : formatNumberRound(entry[propertyName])
      : null;
  };

  if (dataCurr && dataCurr.length === 0 && dataPrev && dataPrev.length === 0) {
    return [];
  } else {
    // get time granularity: 12 months or 31 days or 60 rolling days
    let timeGranularity =
      calendarMode === "decade"
        ? months
        : calendarMode === "year"
        ? days
        : days;
    const combinedData = timeGranularity.map((timeperiod) => ({
      cust: hotelName,
      dt: timeperiod.lettShort,
      black: getValueFromArray(dataCurr, timeperiod.numb),
      yellow: getValueFromArray(dataPrev, timeperiod.numb),
    }));

    return combinedData;
  }
};

// format data to display in charts: one array with rolling dates
const assembleDataForChartRolling = (dataCurr, propertyName, hotelName) => {
  if (dataCurr && dataCurr.length === 0) {
    return [];
  } else {
    const chartData = dataCurr.map((el) => ({
      cust: hotelName,
      dt: new Date(el.dt).toLocaleDateString("it-IT", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      }),
      black: formatNumberRound(el[propertyName]),
    }));

    return chartData;
  }
};

// format data to display in charts: one array with rolling dates
const assembleDataForChartRollingMultiple = (dataCurr, propertyName) => {
  if (dataCurr && dataCurr.length === 0) {
    return [];
  } else {
    const res = [];
    const hotels = Object.keys(dataCurr);

    // for each hotel
    dataCurr[hotels[0]].forEach((_, index) => {
      const entry = {
        dt: new Date(dataCurr[hotels[0]][index].dt).toLocaleDateString(
          "it-IT",
          {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          }
        ),
      };
      hotels.forEach((hotel) => {
        entry[hotel] = dataCurr[hotel][index][propertyName];
      });

      res.push(entry);
    });

    return res;
  }
};

export {
  formatErrorForAlert,
  titleCase,
  formatDateSelected,
  formatMonthSelected,
  assembleBody,
  assembleDataForChart,
  assembleDataForChartRolling,
  assembleDataForChartRollingMultiple,
  formatNumberRound,
  formatNumberFull,
  formatNumberFullNoDecimal,
  computePreviousDate,
};
