import { useEffect, useState, useContext } from "react";
import { AccountContext } from "../../services/AccountService";
import {
  formatErrorForAlert,
  assembleDataForChartRollingMultiple,
  formatNumberFull,
} from "../../services/UtilsService";
import FetchService from "../../services/FetchService";
import LayoutFullSideNav from "../../components/Layout/LayoutFullSideNav";
import LayoutSection from "../../components/Layout/LayoutSection";
import SmallTitleGraphic from "../../components/Title/SmallTitleGraphic";
import RiqChartsLine from "../../components/RiqCharts/RiqChartsLine";
import KpiBlock from "../../components/Kpi/KpiBlock";
import WorkInProgress from "../../components/WorkInProgress/WorkInProgress";
import Alert from "../../components/Alert/Alert";
import Refreshing from "../../components/Refreshing/Refreshing";

const RateShopper = () => {
  const title = "Rate shopper";
  const menu = ["COMPSET RATE"];
  const { hotelName } = useContext(AccountContext);
  const [alert, setAlert] = useState();

  const [dataRateShopper, setDataRateShopper] = useState([]);
  const [dataRateShopperHotels, setDataRateShopperHotels] = useState([]);
  const [dataRateShopperAvg, setDataRateShopperAvg] = useState([]);
  const [dataRateShopperMedian, setDataRateShopperMedian] = useState([]);
  const [lastUpdate, setLastUpdate] = useState([]);
  const [isLoadingRateShopper, setIsLoadingRateShopper] = useState(false);

  useEffect(() => {
    getRateShopper();
  }, []);

  // ==================== Market Demand ====================
  const getRateShopper = () => {
    let api = `/api/dashboard/rate-shopper/${hotelName}/rate-shopper`;
    setIsLoadingRateShopper(true);

    FetchService.get(api)
      .then((res) => {
        setDataRateShopper(
          assembleDataForChartRollingMultiple(res.data.data, "rate")
        );

        setDataRateShopperHotels(Object.keys(res.data.data));

        setDataRateShopperAvg(formatNumberFull(res.data.avg));

        setDataRateShopperMedian(formatNumberFull(res.data.median));

        setLastUpdate(
          new Date(res.data.last_update).toLocaleDateString("it-IT", {
            day: "2-digit",
            month: "2-digit",
            year: "2-digit",
          })
        );
      })
      .catch((err) => {
        setAlert({ message: formatErrorForAlert(err), messageType: "error" });
      })
      .finally(() => setIsLoadingRateShopper(false));
  };

  return (
    <LayoutFullSideNav title={title} menu={menu} lastUpdateForce={lastUpdate}>
      {/* <WorkInProgress
        logo={process.env.PUBLIC_URL + "/imgs/lighthouse.jpg"}
        section={title}
        provider={"Lighthouse (formerly OTA Insight)"}
      /> */}
      <Refreshing conditions={[isLoadingRateShopper]} />
      <Alert
        styleClass={""}
        alertText={alert && alert.message}
        styleColor={alert && alert.messageType}
        onClick={setAlert}
      />

      <LayoutSection>
        <SmallTitleGraphic content={menu[0]} shapeType={1} />
        <RiqChartsLine
          data={dataRateShopper}
          dataGuide={{
            x: "dt",
            linesData: dataRateShopperHotels.map((h) => ({
              dataName: h,
              friendlyName: h,
            })),
          }}
          isLoading={isLoadingRateShopper}
          xInterval={30}
          dotRadius={0}
        />
        <KpiBlock
          valueLeft={`${dataRateShopperAvg}`}
          textLeft="MEDIA RATE"
          valueRight={`${dataRateShopperMedian}`}
          textRight="MEDIAN RATE"
        />
      </LayoutSection>
    </LayoutFullSideNav>
  );
};

export default RateShopper;
